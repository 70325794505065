@charset "UTF-8";
[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  -webkit-transition-duration: 50ms;
          transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  -webkit-transition-delay: 50ms;
          transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  -webkit-transition-duration: .1s;
          transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  -webkit-transition-delay: .1s;
          transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  -webkit-transition-duration: .15s;
          transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  -webkit-transition-delay: .15s;
          transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  -webkit-transition-duration: .2s;
          transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  -webkit-transition-delay: .2s;
          transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  -webkit-transition-duration: .25s;
          transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  -webkit-transition-delay: .25s;
          transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  -webkit-transition-duration: .3s;
          transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  -webkit-transition-delay: .3s;
          transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  -webkit-transition-duration: .35s;
          transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  -webkit-transition-delay: .35s;
          transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  -webkit-transition-duration: .4s;
          transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  -webkit-transition-delay: .4s;
          transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  -webkit-transition-duration: .45s;
          transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  -webkit-transition-delay: .45s;
          transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  -webkit-transition-duration: .5s;
          transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  -webkit-transition-delay: .5s;
          transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  -webkit-transition-duration: .55s;
          transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  -webkit-transition-delay: .55s;
          transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  -webkit-transition-duration: .6s;
          transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  -webkit-transition-delay: .6s;
          transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  -webkit-transition-duration: .65s;
          transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  -webkit-transition-delay: .65s;
          transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  -webkit-transition-duration: .7s;
          transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  -webkit-transition-delay: .7s;
          transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  -webkit-transition-duration: .75s;
          transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  -webkit-transition-delay: .75s;
          transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  -webkit-transition-duration: .8s;
          transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  -webkit-transition-delay: .8s;
          transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  -webkit-transition-duration: .85s;
          transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  -webkit-transition-delay: .85s;
          transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  -webkit-transition-duration: .9s;
          transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  -webkit-transition-delay: .9s;
          transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  -webkit-transition-duration: .95s;
          transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  -webkit-transition-delay: .95s;
          transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  -webkit-transition-duration: 1s;
          transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  -webkit-transition-duration: 1.05s;
          transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.05s;
          transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  -webkit-transition-duration: 1.1s;
          transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.1s;
          transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  -webkit-transition-duration: 1.15s;
          transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.15s;
          transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  -webkit-transition-duration: 1.2s;
          transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.2s;
          transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  -webkit-transition-duration: 1.25s;
          transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.25s;
          transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  -webkit-transition-duration: 1.3s;
          transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.3s;
          transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  -webkit-transition-duration: 1.35s;
          transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.35s;
          transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  -webkit-transition-duration: 1.4s;
          transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.4s;
          transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  -webkit-transition-duration: 1.45s;
          transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.45s;
          transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  -webkit-transition-duration: 1.5s;
          transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.5s;
          transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  -webkit-transition-duration: 1.55s;
          transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.55s;
          transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  -webkit-transition-duration: 1.6s;
          transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.6s;
          transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  -webkit-transition-duration: 1.65s;
          transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.65s;
          transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  -webkit-transition-duration: 1.7s;
          transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.7s;
          transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  -webkit-transition-duration: 1.75s;
          transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.75s;
          transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  -webkit-transition-duration: 1.8s;
          transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.8s;
          transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  -webkit-transition-duration: 1.85s;
          transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.85s;
          transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  -webkit-transition-duration: 1.9s;
          transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.9s;
          transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  -webkit-transition-duration: 1.95s;
          transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.95s;
          transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  -webkit-transition-duration: 2s;
          transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  -webkit-transition-delay: 2s;
          transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  -webkit-transition-duration: 2.05s;
          transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.05s;
          transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  -webkit-transition-duration: 2.1s;
          transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.1s;
          transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  -webkit-transition-duration: 2.15s;
          transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.15s;
          transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  -webkit-transition-duration: 2.2s;
          transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.2s;
          transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  -webkit-transition-duration: 2.25s;
          transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.25s;
          transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  -webkit-transition-duration: 2.3s;
          transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.3s;
          transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  -webkit-transition-duration: 2.35s;
          transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.35s;
          transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  -webkit-transition-duration: 2.4s;
          transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.4s;
          transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  -webkit-transition-duration: 2.45s;
          transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.45s;
          transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  -webkit-transition-duration: 2.5s;
          transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.5s;
          transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  -webkit-transition-duration: 2.55s;
          transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.55s;
          transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  -webkit-transition-duration: 2.6s;
          transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.6s;
          transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  -webkit-transition-duration: 2.65s;
          transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.65s;
          transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  -webkit-transition-duration: 2.7s;
          transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.7s;
          transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  -webkit-transition-duration: 2.75s;
          transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.75s;
          transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  -webkit-transition-duration: 2.8s;
          transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.8s;
          transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  -webkit-transition-duration: 2.85s;
          transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.85s;
          transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  -webkit-transition-duration: 2.9s;
          transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.9s;
          transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  -webkit-transition-duration: 2.95s;
          transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.95s;
          transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  -webkit-transition-duration: 3s;
          transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  -webkit-transition-delay: 3s;
          transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
          transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
          transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
          transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
          transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
          transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
          transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
          transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
          transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
          transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
          transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
          transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
          transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
          transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
          transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
          transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: opacity,transform;
  transition-property: opacity,transform,-webkit-transform;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}

[data-aos=fade-up] {
  -webkit-transform: translate3d(0, 100px, 0);
          transform: translate3d(0, 100px, 0);
}

[data-aos=fade-down] {
  -webkit-transform: translate3d(0, -100px, 0);
          transform: translate3d(0, -100px, 0);
}

[data-aos=fade-right] {
  -webkit-transform: translate3d(-100px, 0, 0);
          transform: translate3d(-100px, 0, 0);
}

[data-aos=fade-left] {
  -webkit-transform: translate3d(100px, 0, 0);
          transform: translate3d(100px, 0, 0);
}

[data-aos=fade-up-right] {
  -webkit-transform: translate3d(-100px, 100px, 0);
          transform: translate3d(-100px, 100px, 0);
}

[data-aos=fade-up-left] {
  -webkit-transform: translate3d(100px, 100px, 0);
          transform: translate3d(100px, 100px, 0);
}

[data-aos=fade-down-right] {
  -webkit-transform: translate3d(-100px, -100px, 0);
          transform: translate3d(-100px, -100px, 0);
}

[data-aos=fade-down-left] {
  -webkit-transform: translate3d(100px, -100px, 0);
          transform: translate3d(100px, -100px, 0);
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: opacity,transform;
  transition-property: opacity,transform,-webkit-transform;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  -webkit-transform: translateZ(0) scale(1);
          transform: translateZ(0) scale(1);
}

[data-aos=zoom-in] {
  -webkit-transform: scale(0.6);
      -ms-transform: scale(0.6);
          transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  -webkit-transform: translate3d(0, 100px, 0) scale(0.6);
          transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  -webkit-transform: translate3d(0, -100px, 0) scale(0.6);
          transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  -webkit-transform: translate3d(-100px, 0, 0) scale(0.6);
          transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  -webkit-transform: translate3d(100px, 0, 0) scale(0.6);
          transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  -webkit-transform: translate3d(0, 100px, 0) scale(1.2);
          transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  -webkit-transform: translate3d(0, -100px, 0) scale(1.2);
          transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  -webkit-transform: translate3d(-100px, 0, 0) scale(1.2);
          transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  -webkit-transform: translate3d(100px, 0, 0) scale(1.2);
          transform: translate3d(100px, 0, 0) scale(1.2);
}

[data-aos^=slide][data-aos^=slide] {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

[data-aos^=slide][data-aos^=slide].aos-animate {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}

[data-aos=slide-up] {
  -webkit-transform: translate3d(0, 100%, 0);
          transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
}

[data-aos^=flip][data-aos^=flip] {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

[data-aos=flip-left] {
  -webkit-transform: perspective(2500px) rotateY(-100deg);
          transform: perspective(2500px) rotateY(-100deg);
}

[data-aos=flip-left].aos-animate {
  -webkit-transform: perspective(2500px) rotateY(0);
          transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  -webkit-transform: perspective(2500px) rotateY(100deg);
          transform: perspective(2500px) rotateY(100deg);
}

[data-aos=flip-right].aos-animate {
  -webkit-transform: perspective(2500px) rotateY(0);
          transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  -webkit-transform: perspective(2500px) rotateX(-100deg);
          transform: perspective(2500px) rotateX(-100deg);
}

[data-aos=flip-up].aos-animate {
  -webkit-transform: perspective(2500px) rotateX(0);
          transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  -webkit-transform: perspective(2500px) rotateX(100deg);
          transform: perspective(2500px) rotateX(100deg);
}

[data-aos=flip-down].aos-animate {
  -webkit-transform: perspective(2500px) rotateX(0);
          transform: perspective(2500px) rotateX(0);
}

/*====================================
=            GRID GLOBALS            =
====================================*/
/*=====  End of GRID GLOBALS  ======*/
/*===================================
=            TRANSITIONS            =
===================================*/
/*=====  End of TRANSITIONS  ======*/
/*=================================
=            STRUCTURE            =
=================================*/
/*=====  End of STRUCTURE  ======*/
/*==============================
=            COLORS            =
==============================*/
/*=====  End of COLORS  ======*/
@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Thin.woff2") format("woff2"), url("../fonts/Montserrat-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-ExtraBold.woff2") format("woff2"), url("../fonts/Montserrat-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Light.woff2") format("woff2"), url("../fonts/Montserrat-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../fonts/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Bold.woff2") format("woff2"), url("../fonts/Montserrat-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Black.woff2") format("woff2"), url("../fonts/Montserrat-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-ExtraLight.woff2") format("woff2"), url("../fonts/Montserrat-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Medium.woff2") format("woff2"), url("../fonts/Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

header,
main,
footer {
  float: left;
  width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

*:after, *:before {
  box-sizing: border-box;
}

.headerWrap,
.mainWrap,
.footerWrap {
  position: relative;
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
div,
ul,
input,
blockquote,
span,
button,
i,
b,
em,
strong {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}

i,
em {
  font-style: italic;
}

b,
strong {
  font-weight: bold;
}

body {
  overflow-x: hidden;
  position: relative;
}

/*=====================================
=            MEDIA QUERIES            =
=====================================*/
/*=====  End of MEDIA QUERIES  ======*/
/*==============================
=            MIXINS            =
==============================*/
/*=====  End of MIXINS  ======*/
/*========================================
=            EXTEND SELECTORS            =
========================================*/
.container,
.row {
  *zoom: 1;
}

.container:before,
.row:before, .container:after,
.row:after {
  content: " ";
  display: table;
}

.container:after,
.row:after {
  clear: both;
}

.headerWrap,
.mainWrap,
.footerWrap, .article_main h2, .article_main h3, .article_main h4, .article_main h5, .box, .box_thumb, .box_content {
  float: left;
  width: 100%;
  clear: both;
}

.column_img img, .banner img, .img_block img, .case_img_block img, .share li a img, .box_thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*=====  End of EXTEND SELECTORS  ======*/
.column-1 {
  width: 8.33333%;
}

.column-2 {
  width: 16.66667%;
}

.column-3 {
  width: 25%;
}

.column-4 {
  width: 33.33333%;
}

.column-5 {
  width: 41.66667%;
}

.column-6 {
  width: 50%;
}

.column-7 {
  width: 58.33333%;
}

.column-8 {
  width: 66.66667%;
}

.column-9 {
  width: 75%;
}

.column-10 {
  width: 83.33333%;
}

.column-11 {
  width: 91.66667%;
}

.column-12 {
  width: 100%;
}

.wow {
  visibility: hidden;
}

[class*='column-'] {
  float: left;
  min-height: 1px;
}

.has_gutter [class*='column-'] {
  padding: 0 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.container {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .container {
    max-width: 732px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .container {
    max-width: 970px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1410px;
    max-width: 88.125rem;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .show-mob {
    display: none !important;
  }
  .show-tab {
    display: none !important;
  }
  .show-tab-big {
    display: none !important;
  }
}

.hidden-mob {
  display: block;
}

.hidden {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .column-mob-1 {
    width: 8.33333%;
  }
  .column-mob-2 {
    width: 16.66667%;
  }
  .column-mob-3 {
    width: 25%;
  }
  .column-mob-4 {
    width: 33.33333%;
  }
  .column-mob-5 {
    width: 41.66667%;
  }
  .column-mob-6 {
    width: 50%;
  }
  .column-mob-7 {
    width: 58.33333%;
  }
  .column-mob-8 {
    width: 66.66667%;
  }
  .column-mob-9 {
    width: 75%;
  }
  .column-mob-10 {
    width: 83.33333%;
  }
  .column-mob-11 {
    width: 91.66667%;
  }
  .column-mob-12 {
    width: 100%;
  }
  .show-mob {
    display: block !important;
  }
  .hidden-mob {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .column-tab-1 {
    width: 8.33333%;
  }
  .column-tab-2 {
    width: 16.66667%;
  }
  .column-tab-3 {
    width: 25%;
  }
  .column-tab-4 {
    width: 33.33333%;
  }
  .column-tab-5 {
    width: 41.66667%;
  }
  .column-tab-6 {
    width: 50%;
  }
  .column-tab-7 {
    width: 58.33333%;
  }
  .column-tab-8 {
    width: 66.66667%;
  }
  .column-tab-9 {
    width: 75%;
  }
  .column-tab-10 {
    width: 83.33333%;
  }
  .column-tab-11 {
    width: 91.66667%;
  }
  .column-tab-12 {
    width: 100%;
  }
  .show-tab {
    display: block !important;
  }
  .hidden-tab {
    display: none !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .column-tab-big-1 {
    width: 8.33333%;
  }
  .column-tab-big-2 {
    width: 16.66667%;
  }
  .column-tab-big-3 {
    width: 25%;
  }
  .column-tab-big-4 {
    width: 33.33333%;
  }
  .column-tab-big-5 {
    width: 41.66667%;
  }
  .column-tab-big-6 {
    width: 50%;
  }
  .column-tab-big-7 {
    width: 58.33333%;
  }
  .column-tab-big-8 {
    width: 66.66667%;
  }
  .column-tab-big-9 {
    width: 75%;
  }
  .column-tab-big-10 {
    width: 83.33333%;
  }
  .column-tab-big-11 {
    width: 91.66667%;
  }
  .column-tab-big-12 {
    width: 100%;
  }
  .show-tablet-big {
    display: block !important;
  }
  .hidden-tablet-big {
    display: none !important;
  }
}

.is-full-width {
  width: 100vw !important;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  max-width: none;
}

.no_padding {
  padding: 0 !important;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

main {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.no-flexboxlegacy body {
  display: block !important;
}

body {
  font-family: 'Montserrat',Helvetica,Arial,sans-serif;
  font-size: 14px;
  color: black;
  position: relative;
  overflow-x: hidden;
}

input,
textarea,
select,
option {
  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  opacity: 1;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder,
option::-webkit-input-placeholder {
  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input:-moz-placeholder,
textarea:-moz-placeholder,
select:-moz-placeholder,
option:-moz-placeholder {
  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder,
option::-moz-placeholder {
  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder,
option:-ms-input-placeholder {
  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

.iphone input,
.iphone textarea,
.iphone select,
.iphone option {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0;
}

select::-ms-expand {
  display: none;
}

/*=================================
=            Normalize            =
=================================*/
mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 15px;
  font-size: 0.9375rem;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

/* Do not show the outline on the skip link target. */
#main[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*=====  End of Normalize  ======*/
.main_header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: auto;
  z-index: 30;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.is--sticky {
  background: rgba(255, 255, 255, 0.8);
}

.is--sticky .menu_line {
  background: #464389;
}

.case_header {
  background: rgba(255, 255, 255, 0.8);
}

.case_header .menu_line {
  background: #464389;
}

.headerWrap {
  padding: 60px 68px 50px 60px;
  padding: 3.75rem 4.25rem 3.125rem 3.75rem;
}

.logo {
  float: left;
  display: inline-block;
  width: 140px;
  width: 8.75rem;
  height: 45px;
  height: 2.8125rem;
}

.moto p {
  float: left;
  width: auto;
  margin-left: 63px;
  margin-left: 3.9375rem;
  font-size: 14px;
  font-size: 0.875rem;
  color: #464389;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 3em;
}

.menu {
  float: right;
  display: inline-block;
  width: 75px;
  width: 4.6875rem;
  height: 55px;
  height: 3.4375rem;
  position: relative;
  cursor: pointer;
}

.menu .menu_line {
  width: 75px;
  width: 4.6875rem;
  height: 4px;
  height: 0.25rem;
  background: #fff;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.menu .menu_line:last-child {
  margin-bottom: 0;
  margin-bottom: 0;
}

.menu_purple {
  float: right;
  display: inline-block;
  width: 77px;
  width: 4.8125rem;
  height: 55px;
  height: 3.4375rem;
  cursor: pointer;
  position: relative;
}

.menu_purple .menu_line {
  width: 75px;
  width: 4.6875rem;
  height: 4px;
  height: 0.25rem;
  background: #464389;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  -webkit-transition-delay: 0s !important;
          transition-delay: 0s !important;
}

.menu_purple .menu_line:last-child {
  margin-bottom: 0;
  margin-bottom: 0;
}

.menu_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: transparent;
  z-index: -1;
  -webkit-transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
  transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
  visibility: hidden;
  opacity: 0;
}

.menu_content .column-4 {
  height: 100%;
}

.gray_background1 {
  background: #222224;
}

.gray_background2 {
  background: #171719;
}

.gray_background3 {
  background: #000;
  position: relative;
}

.column_content {
  float: left;
  margin-top: 355px;
  margin-top: 22.1875rem;
  padding-left: 70px;
  padding-left: 4.375rem;
  padding-right: 70px;
  padding-right: 4.375rem;
}

.column_content a {
  float: left;
  text-decoration: none;
  color: white;
  font-size: 58px;
  font-size: 3.625rem;
  position: relative;
  margin-bottom: 54px;
  margin-bottom: 3.375rem;
}

.column_content a.orange_border::before {
  content: '';
  position: absolute;
  height: 3px;
  height: 0.1875rem;
  width: 156px;
  width: 9.75rem;
  background: #f07950;
  left: 0;
  bottom: -30px;
}

.column_content a.green_border::before {
  content: '';
  position: absolute;
  height: 3px;
  height: 0.1875rem;
  width: 156px;
  width: 9.75rem;
  background: #33a692;
  left: 0;
  bottom: -30px;
}

.column_content a.blue_border::before {
  content: '';
  position: absolute;
  height: 3px;
  height: 0.1875rem;
  width: 156px;
  width: 9.75rem;
  background: #464389;
  left: 0;
  bottom: -30px;
}

.column_content p {
  float: left;
  width: 100%;
  color: rgba(255, 255, 255, 0.3);
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.56;
}

.column_content ul {
  float: left;
  width: 100%;
  margin-top: 33px;
  margin-top: 2.0625rem;
}

.column_content ul.orange_hover a:hover {
  border-bottom: 3px solid #f07950;
}

.column_content ul.green_hover a:hover {
  border-bottom: 3px solid #33a692;
}

.column_content ul.blue_hover a:hover {
  border-bottom: 3px solid #464389;
}

.column_content li {
  float: left;
  width: 100%;
  margin-bottom: 32px;
  margin-bottom: 2rem;
}

.column_content li:last-child {
  margin-bottom: 0;
  margin-bottom: 0;
}

.column_content li a {
  text-decoration: none;
  color: #fff;
  font-size: 25px;
  font-size: 1.5625rem;
  font-weight: 400;
  margin-bottom: -13px;
  margin-bottom: -0.8125rem;
  padding-bottom: 10px;
  padding-bottom: 0.625rem;
  border-bottom: 3px solid transparent;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.address {
  position: absolute;
  bottom: 0;
  margin-bottom: 62px;
  margin-bottom: 3.875rem;
  margin-left: 70px;
  margin-left: 4.375rem;
}

.address p {
  color: rgba(255, 255, 255, 0.3);
  font-size: 17px;
  font-size: 1.0625rem;
  line-height: 1.56em;
}

.is--active {
  visibility: visible !important;
  opacity: 1 !important;
}

.is--hidden {
  visibility: hidden !important;
  opacity: 0 !important;
}

.lock_scroll {
  overflow: hidden;
}

.active div.menu_line:nth-child(2),
.active div.menu_line:nth-child(3) {
  width: 0;
  opacity: 0;
}

.active div.menu_line:nth-child(1) {
  position: absolute;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
  top: 25px;
  background: #fff;
}

.active div.menu_line:nth-child(4) {
  position: absolute;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 26px;
  background: #fff;
}

html {
  font-family: 'Montserrat';
}

.block_section {
  float: left;
  width: 100%;
  clear: both;
}

body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.full_screen_page {
  height: 100vh;
  width: 100vw;
  position: fixed;
}

.full_screen_page a.homepage-column {
  float: left;
  height: 100%;
  text-decoration: none;
}

.full_screen_page a.homepage-column:hover .more p {
  padding-right: 100px;
  padding-right: 6.25rem;
}

.column_img {
  float: left;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.vertical_block_1 {
  position: relative;
}

.vertical_block_1 .gradient_holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(27%, rgba(59, 168, 149, 0.953116)), color-stop(37%, rgba(245, 245, 246, 0)));
  background: linear-gradient(0deg, rgba(59, 168, 149, 0.953116) 27%, rgba(245, 245, 246, 0) 37%);
  z-index: 5;
}

.vertical_block_2 {
  position: relative;
}

.vertical_block_2 .gradient_holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(27%, rgba(72, 68, 134, 0.95)), color-stop(37%, rgba(245, 245, 246, 0)));
  background: linear-gradient(0deg, rgba(72, 68, 134, 0.95) 27%, rgba(245, 245, 246, 0) 37%);
  z-index: 5;
}

.vertical_block_3 {
  position: relative;
}

.vertical_block_3 .gradient_holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(27%, rgba(238, 124, 79, 0.95)), color-stop(37%, rgba(245, 245, 246, 0)));
  background: linear-gradient(0deg, rgba(238, 124, 79, 0.95) 27%, rgba(245, 245, 246, 0) 37%);
  z-index: 5;
}

.category_title {
  width: 77%;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 7;
  padding: 55px 50px 55px 0;
  padding: 3.4375rem 3.125rem 3.4375rem 0;
  text-align: right;
}

.category_title h1 {
  font-size: 60px;
  font-size: 3.75rem;
  color: #fff;
  font-weight: 500;
  text-align: right;
  -webkit-transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
  transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
  margin-bottom: 52px;
  margin-bottom: 3.25rem;
  line-height: 1em;
}

.more {
  float: right;
}

.more p {
  color: #fff;
  font-size: 11px;
  font-size: 0.7075rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  position: relative;
  -webkit-transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
  transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
  padding-right: 67px;
  padding-right: 4.1875rem;
}

.more p:after {
  content: "";
  background: url("../../src/images/more_arrow.svg") no-repeat center;
  width: 57px;
  width: 3.5625rem;
  height: 24px;
  height: 1.5rem;
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: -5px;
  bottom: -0.3125rem;
  background-size: cover;
}

.banner {
  float: left;
  width: 100%;
  height: 100vh;
  position: relative;
}

.banner__title {
  position: absolute;
  bottom: 67px;
  bottom: 4.1875rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}

.banner__title h1 {
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  font-size: 90px;
  font-size: 5.625rem;
}

.banner_small {
  height: 400px;
}

.text_and_image_blocks {
  float: left;
  width: 100%;
  height: 653px;
  height: 40.8125rem;
}

.text_block {
  float: left;
  width: 50%;
  height: 100%;
  display: inline-block;
  padding: 93px 168px 107px 0;
  padding: 5.8125rem 10.5rem 6.6875rem 0;
}

.text_block__inner {
  float: right;
  width: 525px;
  width: 32.8125rem;
}

.text_block__inner h2 {
  font-size: 40px;
  font-size: 2.5rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 70px;
  margin-bottom: 4.375rem;
}

.text_block__inner p {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.35em;
  color: #000;
}

.img_block {
  float: right;
  display: inline-block;
  width: 50%;
  height: 100%;
}

.flipped_blocks .text_block {
  float: right;
  padding: 93px 0 107px 100px;
  padding: 5.8125rem 0 6.6875rem 6.25rem;
}

.flipped_blocks .text_block__inner {
  float: left;
}

.flipped_blocks .img_block {
  float: left;
}

.text_block_color1 {
  background: #232323;
}

.text_block_color1 .text_block__inner h2, .text_block_color1 .text_block__inner p {
  color: #fff;
}

.text_block_color2 {
  background: #33a692;
}

.text_block_color2 .text_block__inner h2, .text_block_color2 .text_block__inner p {
  color: #fff;
}

.partners {
  float: left;
  width: 100%;
  padding: 0 135px;
  padding: 0 8.4375rem;
  padding-top: 118px;
  padding-top: 7.375rem;
  padding-bottom: 118px;
  padding-bottom: 7.375rem;
}

.partners h2 {
  font-size: 40px;
  font-size: 2.5rem;
  color: #232323;
  font-weight: 600;
  text-align: center;
  margin-bottom: 94px;
  margin-bottom: 5.875rem;
}

.partners ul {
  float: left;
  width: 100%;
}

.partners ul li {
  float: left;
  display: inline-block;
  height: 200px;
  position: relative;
}

.partners ul li img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.case_banner__title {
  position: absolute;
  bottom: 383px;
  bottom: 23.9375rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
  padding: 0px 220px;
}

.case_banner__title h1 {
  color: #fff;
  font-weight: 600;
  font-size: 90px;
  font-size: 5.625rem;
  text-align: center;
}

.case_text_block {
  float: left;
  width: 62%;
  height: 100%;
  display: inline-block;
  padding: 57px 350px 43px 0;
  padding: 3.5625rem 21.875rem 2.6875rem 0;
}

.case_text_block__inner {
  float: right;
  width: 525px;
  width: 32.8125rem;
}

.case_text_block__inner h2 {
  font-size: 40px;
  font-size: 2.5rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
}

.case_text_block__inner p {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.35em;
  color: #000;
  margin-bottom: 47px;
  margin-bottom: 2.9375rem;
}

.case_img_block {
  float: right;
  width: 38%;
  display: inline-block;
  height: 653px;
  height: 40.8125rem;
}

.case_img_block_inner {
  width: 50vw;
  height: 100%;
}

.case_text_and_image_blocks {
  float: left;
  width: 100%;
  overflow: hidden;
  height: 653px;
  height: 40.8125rem;
}

.share {
  float: left;
}

.share p {
  font-size: 14px;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: #7a7a7a;
  font-weight: 600;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
}

.share ul {
  float: left;
  width: 100%;
  display: inline-block;
}

.share li {
  float: left;
  display: inline-block;
  width: 26px;
  width: 1.625rem;
  height: 25px;
  height: 1.5625rem;
  margin-right: 18px;
  margin-right: 1.125rem;
}

.share li:last-child {
  margin-right: 0;
  margin-right: 0;
}

.share li a {
  text-decoration: none;
}

.cases_navigation {
  float: left;
  width: 100%;
  background: #232323;
  padding: 60px 0;
  padding: 3.75rem 0;
}

.cases_navigation ul {
  float: left;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.cases_navigation li {
  float: left;
  display: inline-block;
  width: auto;
}

.cases_navigation li a {
  float: left;
  text-decoration: none;
  font-size: 15px;
  font-size: 0.9375rem;
  color: #6f6f6f;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}

.prev_case a {
  padding-left: 113px;
  padding-left: 7.0625rem;
}

.prev_case a::before {
  content: "";
  background: url("../../src/images/prev_arrow.svg") no-repeat center;
  position: absolute;
  width: 90px;
  width: 5.625rem;
  height: 40px;
  height: 2.5rem;
  left: 0;
  bottom: -12px;
  bottom: -0.75rem;
  background-size: contain;
}

.all_cases a {
  padding-left: 54px;
  padding-left: 3.375rem;
}

.all_cases a::before {
  content: '';
  background: url("../../src/images/all_cases_icon.svg") no-repeat center;
  width: 35px;
  width: 2.1875rem;
  height: 32px;
  height: 2rem;
  position: absolute;
  left: 0;
  bottom: -8px;
  bottom: -0.5rem;
  background-size: contain;
}

.next_case a {
  padding-right: 113px;
  padding-right: 7.0625rem;
}

.next_case a::after {
  content: '';
  background: url("../../src/images/next_case_arrow.svg") no-repeat center;
  position: absolute;
  width: 90px;
  width: 5.625rem;
  height: 40px;
  height: 2.5rem;
  right: 0;
  right: 0;
  right: 0;
  bottom: -11px;
  bottom: -0.6875rem;
  background-size: contain;
}

.wpcf7 {
  width: 700px;
  padding: 20px 20px 20px 50px !important;
  margin: 50px auto !important;
}

@media only screen and (max-width: 767px) {
  .wpcf7 {
    width: 100%;
    padding: 0 !important;
  }
}

.wpcf7 input,
.wpcf7 textarea {
  font-size: 16px;
  background-color: #f5f5f5;
  border: none;
  width: 100%;
  padding: 10px 20px;
  outline: 0;
  margin-top: 5px;
}

.wpcf7 input[type="submit"] {
  float: right;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  background: #464388;
  padding: 10px 29px;
  border: none;
  border-radius: 0px;
  width: auto;
  text-transform: uppercase;
  float: right;
  letter-spacing: 1px;
  cursor: pointer;
}

.wpcf7 input:hover[type="submit"] {
  background: #494949;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.wpcf7 input:active[type="submit"] {
  background: #000000;
}

.wpcf7 label {
  margin-bottom: 15px;
  float: left;
  width: 100%;
}

div.wpcf7-response-output {
  float: left;
  width: 100%;
}

span.wpcf7-list-item {
  margin: 0;
  padding-bottom: 20px;
}

.header_height_space {
  margin-top: 200px;
  margin-top: 12.5rem;
}

@media only screen and (max-width: 767px) {
  .header_height_space {
    margin-top: 20px;
    margin-top: 1.25rem;
  }
}

.article_post {
  margin-top: 60px;
  margin-top: 3.75rem;
}

.article_post .article_header {
  text-align: center;
  font-size: 38px;
  font-size: 2.375rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 70px;
  margin-bottom: 4.375rem;
}

@media only screen and (max-width: 767px) {
  .article_post .article_header {
    margin-bottom: 30px;
    margin-bottom: 1.875rem;
  }
}

.article_post h2 {
  font-size: 32px;
  font-size: 2rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.article_post p {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.35em;
  color: #000;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.article_main {
  font-size: 18px;
  font-size: 1.125rem;
  color: #150F3E;
}

.article_main p, .article_main ul, .article_main ol, .article_main h2, .article_main h3, .article_main h4, .article_main h5, .article_main blockquote {
  margin-bottom: 28px;
  margin-bottom: 1.75rem;
  line-height: 1.56em;
}

.article_main p:last-child, .article_main ul:last-child, .article_main ol:last-child, .article_main h2:last-child, .article_main h3:last-child, .article_main h4:last-child, .article_main h5:last-child, .article_main blockquote:last-child {
  margin-bottom: 0;
}

.article_main h2, .article_main h3, .article_main h4, .article_main h5 {
  font-weight: bold;
  margin-top: 10px;
  margin-top: 0.625rem;
}

.article_main h2 {
  font-size: 32px;
  font-size: 2rem;
}

.article_main h3 {
  font-size: 28px;
  font-size: 1.75rem;
}

.article_main h4 {
  font-size: 24px;
  font-size: 1.5rem;
}

.article_main h5 {
  font-size: 20px;
  font-size: 1.25rem;
}

.article_main .instagram-media, .article_main .fb-video, .article_main .fb-post, .article_main .twitter-tweet, .article_main iframe {
  margin-bottom: 28px;
  margin-bottom: 1.75rem;
  line-height: 1.56em !important;
}

.article_main .instagram-media:last-child, .article_main .fb-video:last-child, .article_main .fb-post:last-child, .article_main .twitter-tweet:last-child, .article_main iframe:last-child {
  margin-bottom: 0;
}

.article_main ul {
  list-style-type: disc;
  padding-left: 24px;
  padding-left: 1.5rem;
}

.article_main ol {
  list-style-type: decimal;
  padding-left: 24px;
  padding-left: 1.5rem;
}

.article_main a {
  color: #464389;
  text-decoration: none;
}

.article_main blockquote {
  margin-left: -74px;
  margin-left: -4.625rem;
  padding-left: 74px;
  padding-left: 4.625rem;
  padding-top: 5px;
  padding-top: 0.3125rem;
  padding-bottom: 5px;
  padding-bottom: 0.3125rem;
  font-size: 20px;
  font-size: 1.25rem;
  float: left;
  border-left: 5px solid #464389;
  font-style: italic;
  quotes: "“" "”" "‘" "’";
}

.article_main blockquote p:before {
  content: open-quote;
  float: left;
  font-weight: bold;
  font-size: 42px;
  font-size: 2.625rem;
  color: #464389;
  margin-right: 10px;
}

.box {
  color: inherit;
  text-decoration: none;
  margin-bottom: 30px;
  margin-bottom: 1.875rem;
}

.box_thumb {
  height: 250px;
  height: 15.625rem;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.box_content {
  color: #150F3E;
}

.box_content h1 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.3em;
  font-weight: 600;
  margin-bottom: 7px;
  margin-bottom: 0.4375rem;
}

.box_content p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
}

.home-top-pagination {
  margin-top: 50px;
  margin-top: 3.125rem;
  padding-top: 40px;
  padding-top: 2.5rem;
  padding-bottom: 54px;
  padding-bottom: 3.375rem;
  border-top: 1px solid rgba(55, 67, 82, 0.1);
}

.home-top-pagination a {
  float: left;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 47px;
  width: 2.9375rem;
  height: 47px;
  height: 2.9375rem;
  border-radius: 23.5px;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 900;
  letter-spacing: 0.8px;
  text-align: center;
  color: #374352;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.home-top-pagination a:hover {
  color: #00bbe1;
}

.home-top-pagination a.active {
  box-shadow: 0 2px 12px 0 rgba(55, 67, 82, 0.15);
}

.clearleft {
  clear: left;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  html {
    font-size: 80%;
  }
  .headerWrap {
    padding: 30px 38px 20px 30px;
    padding: 1.875rem 2.375rem 1.25rem 1.875rem;
  }
  .category_title h1 {
    font-size: 48px;
  }
}

@media only screen and (max-device-width: 960px) and (orientation: landscape) {
  #header {
    height: 60px !important;
  }
  #header .headerWrap {
    padding: 14px 20px 13px 20px !important;
  }
  #header .headerWrap .logo {
    width: 100px;
  }
  #header .headerWrap .moto p {
    font-size: 12px;
  }
  #header .headerWrap .menu_content {
    overflow: scroll;
  }
  #header .headerWrap .menu_content .column-4 {
    height: 280px;
  }
  #header .headerWrap #menu {
    width: 50px;
  }
  #header .headerWrap #menu .menu_line {
    width: 100%;
    margin-bottom: 6px;
    height: 3px;
  }
  #header .headerWrap .menu_purple {
    width: 50px;
  }
  #header .headerWrap .menu_purple .menu_line {
    width: 100%;
    margin-bottom: 6px;
    height: 3px;
  }
  .mainWrap .banner {
    height: 75vh;
  }
  .mainWrap .banner .case_banner__title {
    top: 60%;
  }
  .mainWrap .block_section .cases_navigation {
    padding: 30px 0;
    padding: 1.875rem 0;
  }
  .mainWrap .full_screen_page {
    position: static;
    height: auto;
  }
  .mainWrap .full_screen_page a.homepage-column {
    float: left;
    height: 280px;
  }
  .mainWrap .full_screen_page a.homepage-column .column_img img {
    height: 100%;
  }
  .mainWrap .full_screen_page a.homepage-column .category_title {
    bottom: 30px !important;
  }
  .mainWrap .full_screen_page a.homepage-column .category_title h1 {
    font-size: 40px !important;
    margin-bottom: 25px;
  }
  .mainWrap .full_screen_page a.homepage-column .category_title .more p {
    font-size: 13px !important;
  }
}

@media only screen and (max-device-width: 1024px) and (orientation: landscape) {
  .category_title {
    bottom: 18px !important;
  }
  .category_title h1 {
    font-size: 45px !important;
  }
  .case_banner__title {
    bottom: 50px !important;
  }
  .case_banner__title h1 {
    font-size: 65px;
  }
  .main_header {
    height: 85px !important;
  }
  .headerWrap {
    padding: 20px 20px 10px 20px !important;
  }
  .menu .menu_line {
    background: #464389;
  }
  .banner__title {
    bottom: 15px;
  }
  .banner__title h1 {
    font-size: 65px;
  }
  .column_content {
    margin-top: 70px !important;
  }
  .column_content a {
    font-size: 30px !important;
  }
  .column_content p {
    margin-top: 30px !important;
  }
  .column_content ul {
    margin-top: 35px !important;
  }
  .column_content ul li {
    margin-bottom: 25px;
  }
  .column_content ul li a {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 767px) {
  .main_header {
    height: 64px;
    height: 4rem;
  }
  .headerWrap {
    padding: 15px;
    padding: 0.9375rem;
    height: 100%;
  }
  .logo {
    width: 100px;
    width: 6.25rem;
  }
  .moto {
    display: none;
  }
  .menu {
    width: 45px;
    width: 2.8125rem;
  }
  .menu .menu_line {
    width: 100%;
    height: 3px;
    height: 0.1875rem;
    margin-bottom: 7px;
    margin-bottom: 0.4375rem;
    background: #464389;
  }
  .active div.menu_line:first-child {
    top: 15px;
    top: 0.9375rem;
  }
  .active div.menu_line:nth-child(4) {
    top: 15px;
    top: 0.9375rem;
  }
  .menu_purple {
    width: 45px;
    width: 2.8125rem;
  }
  .menu_purple .menu_line {
    width: 100%;
    height: 3px;
    height: 0.1875rem;
    margin-bottom: 7px;
    margin-bottom: 0.4375rem;
  }
  .full_screen_page {
    position: static;
    height: auto;
  }
  .full_screen_page a.homepage-column {
    float: left;
    height: 300px;
  }
  .full_screen_page a.homepage-column .category_title {
    opacity: 1 !important;
    -webkit-transform: translateZ(0) !important;
            transform: translateZ(0) !important;
  }
  .category_title {
    width: 100%;
    padding: 20px;
    padding: 1.25rem;
  }
  .category_title h1 {
    font-size: 35px;
    font-size: 2.1875rem;
    font-size: 35px;
    margin-bottom: 25px;
    margin-bottom: 1.5625rem;
  }
  .vertical_block_first .gradient_holder {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(27%, rgba(59, 168, 149, 0.953116)), to(rgba(244, 244, 245, 0)));
    background: linear-gradient(0deg, rgba(59, 168, 149, 0.953116) 27%, rgba(244, 244, 245, 0) 100%);
  }
  .vertical_block_second .gradient_holder {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(27%, rgba(72, 68, 134, 0.95)), to(rgba(244, 244, 245, 0)));
    background: linear-gradient(0deg, rgba(72, 68, 134, 0.95) 27%, rgba(244, 244, 245, 0) 100%);
  }
  .vertical_block_third .gradient_holder {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(27%, rgba(238, 124, 79, 0.95)), to(rgba(244, 244, 245, 0)));
    background: linear-gradient(0deg, rgba(238, 124, 79, 0.95) 27%, rgba(244, 244, 245, 0) 100%);
  }
  .menu_content .column-4 {
    height: 33.3333%;
  }
  .column_content {
    margin-top: 50px;
    margin-top: 3.125rem;
    padding: 15px;
    padding: 0.9375rem;
  }
  .column_content a {
    font-size: 25px;
    font-size: 1.5625rem;
    margin-bottom: 0;
    margin-bottom: 0;
  }
  .column_content a::before {
    width: 93px !important;
    bottom: -6px !important;
  }
  .column_content p {
    font-size: 13px;
    font-size: 0.8125rem;
    margin-top: 20px;
    margin-top: 1.25rem;
    line-height: 1.2em;
  }
  .column_content ul {
    margin-top: 22px;
    margin-top: 1.375rem;
  }
  .column_content ul li {
    margin-bottom: 15px;
    margin-bottom: 0.9375rem;
  }
  .column_content ul li a {
    font-size: 15px;
    font-size: 0.9375rem;
    padding-bottom: 5px;
    padding-bottom: 0.3125rem;
  }
  .address {
    position: relative;
    float: left;
    width: 100%;
    margin: 0;
    padding: 0 15px 15px 15px;
    padding: 0 0.9375rem 0.9375rem 0.9375rem;
  }
  .address p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.2em;
  }
  .banner {
    height: 45vh;
  }
  .banner__title {
    bottom: 30px;
    bottom: 1.875rem;
  }
  .banner__title h1 {
    font-size: 33px;
    font-size: 2.0625rem;
  }
  .text_and_image_blocks {
    height: auto;
  }
  .text_block {
    float: left;
    width: 100%;
    padding: 30px 15px;
    padding: 1.875rem 0.9375rem;
  }
  .text_block__inner {
    width: 100%;
  }
  .text_block__inner h2 {
    font-size: 33px;
    font-size: 2.0625rem;
    margin-bottom: 15px;
    margin-bottom: 0.9375rem;
  }
  .text_block__inner p {
    font-size: 16px;
    font-size: 1rem;
  }
  .flipped_blocks .text_block {
    float: left;
    width: 100%;
    padding: 30px 15px;
    padding: 1.875rem 0.9375rem;
  }
  .flipped_blocks .text_block__inner {
    width: 100%;
  }
  .img_block {
    float: left;
    width: 100%;
  }
  .img_block img {
    margin-bottom: -2px;
    margin-bottom: -0.125rem;
  }
  .partners {
    padding: 30px 0 50px 0;
    padding: 1.875rem 0 3.125rem 0;
  }
  .partners h2 {
    font-size: 33px;
    font-size: 2.0625rem;
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .partners ul li {
    height: 110px;
    height: 6.875rem;
  }
  .case_banner__title {
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .case_banner__title h1 {
    font-size: 33px;
    font-size: 2.0625rem;
    text-align: center;
  }
  .case_text_and_image_blocks {
    height: auto;
  }
  .case_text_block {
    width: 100%;
    padding: 15px 0;
  }
  .case_text_block .case_text_block__inner {
    width: 100%;
  }
  .case_text_block .case_text_block__inner h2 {
    font-size: 33px;
    font-size: 2.0625rem;
    margin-bottom: 15px;
    margin-bottom: 0.9375rem;
  }
  .case_text_block .case_text_block__inner p {
    font-size: 16px;
    font-size: 1rem;
    margin-bottom: 15px;
    margin-bottom: 0.9375rem;
  }
  .case_img_block {
    width: 100%;
    height: auto;
  }
  .case_img_block .case_img_block_inner {
    width: 100%;
    height: 250px;
  }
  .case_img_block .case_img_block_inner img {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: 50%;
    width: 100vw;
    height: 100%;
  }
  .cases_navigation {
    padding: 30px 0;
    padding: 1.875rem 0;
  }
  .cases_navigation li a {
    font-size: 10px;
    font-size: 0.625rem;
  }
  .prev_case a {
    padding-left: 30px;
    padding-left: 1.875rem;
  }
  .prev_case a::before {
    width: 23px;
    width: 1.4375rem;
    bottom: -14px;
    bottom: -0.875rem;
  }
  .next_case a {
    padding-right: 30px;
    padding-right: 1.875rem;
  }
  .next_case a::after {
    width: 23px;
    width: 1.4375rem;
    bottom: -14px;
    bottom: -0.875rem;
  }
  .all_cases a {
    padding-left: 30px;
    padding-left: 1.875rem;
  }
  .all_cases a::before {
    width: 15px;
    width: 0.9375rem;
    bottom: -10px;
    bottom: -0.625rem;
  }
  .share li {
    width: 22px;
    width: 1.375rem;
    height: 21px;
    height: 1.3125rem;
    margin-right: 13px;
    margin-right: 0.8125rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .main_header {
    height: 108px;
    height: 6.75rem;
  }
  .headerWrap {
    height: 100%;
    padding: 30px 20px;
    padding: 1.875rem 1.25rem;
  }
  .full_screen_page a.homepage-column {
    float: left;
    height: 33.333333%;
  }
  .menu .menu_line {
    background: #464389;
  }
  .vertical_block_first .gradient_holder {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(27%, rgba(59, 168, 149, 0.953116)), to(rgba(244, 244, 245, 0)));
    background: linear-gradient(0deg, rgba(59, 168, 149, 0.953116) 27%, rgba(244, 244, 245, 0) 100%);
  }
  .vertical_block_second .gradient_holder {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(27%, rgba(72, 68, 134, 0.95)), to(rgba(244, 244, 245, 0)));
    background: linear-gradient(0deg, rgba(72, 68, 134, 0.95) 27%, rgba(244, 244, 245, 0) 100%);
  }
  .vertical_block_third .gradient_holder {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(27%, rgba(238, 124, 79, 0.95)), to(rgba(244, 244, 245, 0)));
    background: linear-gradient(0deg, rgba(238, 124, 79, 0.95) 27%, rgba(244, 244, 245, 0) 100%);
  }
  .category_title {
    width: 100%;
    padding: 20px;
    padding: 1.25rem;
    bottom: 45px;
  }
  .category_title h1 {
    font-size: 45px;
    font-size: 2.8125rem;
    margin-bottom: 45px;
    margin-bottom: 2.8125rem;
  }
  .category_title .more p {
    font-size: 16px;
    font-size: 1rem;
  }
  .menu_content .column-4 {
    height: 33.3333%;
    margin-bottom: -1px;
    margin-bottom: -0.0625rem;
  }
  .column_content {
    margin-top: 100px;
    margin-top: 6.25rem;
    padding: 20px;
    padding: 1.25rem;
  }
  .column_content a {
    font-size: 40px;
    font-size: 2.5rem;
    margin-bottom: 0;
    margin-bottom: 0;
  }
  .column_content a::before {
    width: 115px !important;
    bottom: -10px !important;
  }
  .column_content p {
    font-size: 16px;
    font-size: 1rem;
    margin-top: 30px;
    margin-top: 1.875rem;
    line-height: 1.2em;
  }
  .column_content ul {
    margin-top: 50px;
    margin-top: 3.125rem;
  }
  .column_content ul li {
    width: 50%;
    margin-bottom: 35px;
    margin-bottom: 2.1875rem;
  }
  .column_content ul li a {
    font-size: 25px;
    font-size: 1.5625rem;
    padding-bottom: 5px;
    padding-bottom: 0.3125rem;
  }
  .address {
    right: 0;
    bottom: 0;
    margin-right: 20px;
    margin-right: 1.25rem;
    margin-bottom: 30px;
    margin-bottom: 1.875rem;
  }
  .address p {
    font-size: 16px;
    font-size: 1rem;
  }
  .banner {
    height: 50vh;
  }
  .banner .banner__title {
    bottom: 53px;
    bottom: 3.3125rem;
  }
  .banner .banner__title h1 {
    font-size: 60px;
    font-size: 3.75rem;
  }
  .text_and_image_blocks {
    height: auto;
  }
  .text_block {
    float: left;
    width: 100%;
    padding: 50px 20px;
    padding: 3.125rem 1.25rem;
  }
  .text_block__inner {
    width: 100%;
  }
  .text_block__inner h2 {
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .img_block {
    float: left;
    width: 100%;
  }
  .img_block img {
    margin-bottom: -2px;
    margin-bottom: -0.125rem;
  }
  .flipped_blocks .text_block {
    float: left;
    width: 100%;
    padding: 50px 20px;
    padding: 3.125rem 1.25rem;
  }
  .flipped_blocks .text_block__inner {
    width: 100%;
  }
  .flipped_blocks .text_block__inner h2 {
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .case_banner__title {
    padding: 0 60px;
    padding: 0 3.75rem;
    bottom: 100px;
    bottom: 6.25rem;
  }
  .case_banner__title h1 {
    font-size: 60px;
    font-size: 3.75rem;
  }
  .case_text_block {
    width: 100%;
    padding: 50px 0;
    padding: 3.125rem 0;
  }
  .case_text_block__inner {
    width: 100%;
  }
  .case_text_and_image_blocks {
    height: auto;
  }
  .case_img_block {
    width: 100%;
    height: auto;
  }
  .case_img_block_inner {
    width: 100%;
    height: 450px;
  }
  .case_img_block_inner img {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: 50%;
    width: 100vw;
  }
  .column_img img {
    height: 120%;
  }
  .partners {
    padding: 50px;
    padding: 3.125rem;
  }
  .partners h2 {
    margin-bottom: 30px;
    margin-bottom: 1.875rem;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .main_header {
    height: 116px;
    height: 7.25rem;
  }
  .headerWrap {
    padding: 35px 20px;
  }
  .banner {
    height: 50vh;
  }
  .case_banner__title {
    bottom: 172px;
    bottom: 10.75rem;
  }
  .case_text_block {
    width: 100%;
    padding: 30px 0;
  }
  .case_text_block__inner {
    width: 100%;
  }
  .case_text_and_image_blocks {
    height: auto;
  }
  .case_img_block {
    float: left;
    width: 100%;
  }
  .case_img_block .case_img_block_inner {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: 50%;
    width: 100vw;
  }
  .full_screen_page a.homepage-column {
    float: left;
    height: 33.3333%;
  }
  .vertical_block_first .gradient_holder {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(27%, rgba(59, 168, 149, 0.953116)), to(rgba(244, 244, 245, 0)));
    background: linear-gradient(0deg, rgba(59, 168, 149, 0.953116) 27%, rgba(244, 244, 245, 0) 100%);
  }
  .vertical_block_second .gradient_holder {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(27%, rgba(72, 68, 134, 0.95)), to(rgba(244, 244, 245, 0)));
    background: linear-gradient(0deg, rgba(72, 68, 134, 0.95) 27%, rgba(244, 244, 245, 0) 100%);
  }
  .vertical_block_third .gradient_holder {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(27%, rgba(238, 124, 79, 0.95)), to(rgba(244, 244, 245, 0)));
    background: linear-gradient(0deg, rgba(238, 124, 79, 0.95) 27%, rgba(244, 244, 245, 0) 100%);
  }
  .category_title {
    width: 100%;
    padding: 20px;
    padding: 1.25rem;
    bottom: 45px;
  }
  .category_title h1 {
    font-size: 60px;
    font-size: 3.75rem;
    margin-bottom: 45px;
    margin-bottom: 2.8125rem;
  }
  .category_title .more p {
    font-size: 16px;
    font-size: 1rem;
  }
  .menu_content .column-4 {
    height: 33.3333% !important;
    margin-bottom: -1px;
    margin-bottom: -0.0625rem;
  }
  .column_content {
    margin-top: 100px;
    margin-top: 6.25rem;
    padding: 20px;
    padding: 1.25rem;
  }
  .column_content a {
    font-size: 40px;
    font-size: 2.5rem;
    margin-bottom: 0;
    margin-bottom: 0;
  }
  .column_content a::before {
    width: 115px !important;
    bottom: -10px !important;
  }
  .column_content p {
    font-size: 18px;
    font-size: 1.125rem;
    margin-top: 53px;
    margin-top: 3.3125rem;
    line-height: 1.2em;
  }
  .column_content ul {
    margin-top: 50px;
    margin-top: 3.125rem;
  }
  .column_content ul li {
    width: 50%;
    margin-bottom: 35px;
    margin-bottom: 2.1875rem;
  }
  .column_content ul li a {
    font-size: 25px;
    font-size: 1.5625rem;
    padding-bottom: 5px;
    padding-bottom: 0.3125rem;
  }
  .address {
    right: 0;
    bottom: 0;
    margin-right: 20px;
    margin-right: 1.25rem;
    margin-bottom: 70px;
    margin-bottom: 4.375rem;
  }
  .address p {
    font-size: 16px;
    font-size: 1rem;
  }
  .text_and_image_blocks {
    height: auto;
  }
  .text_block {
    width: 100%;
    padding: 50px 20px;
  }
  .text_block .text_block__inner {
    width: 100%;
  }
  .text_block .text_block__inner h2 {
    margin-bottom: 30px;
    margin-bottom: 1.875rem;
  }
  .img_block {
    float: left;
    width: 100%;
    height: 600px;
  }
  .img_block img {
    margin-bottom: -2px;
    margin-bottom: -0.125rem;
  }
  .flipped_blocks {
    height: auto;
  }
  .flipped_blocks .text_block {
    float: left;
    width: 100%;
    padding: 50px 20px;
    padding: 3.125rem 1.25rem;
  }
  .flipped_blocks .img_block {
    float: left;
    width: 100%;
    height: 600px;
  }
  .flipped_blocks .img_block img {
    margin-bottom: -2px;
    margin-bottom: -0.125rem;
  }
  .column_img img {
    height: 120%;
  }
  .partners {
    padding: 60px;
    padding: 3.75rem;
  }
  .partners h2 {
    margin-bottom: 30px;
    margin-bottom: 1.875rem;
  }
}
