.main_header{
	position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: auto;
    z-index: 30;
    opacity: 1;
    visibility: visible;
    transition: all .3s cubic-bezier(0.3, 0.0, 0.2, 1); 
}

.is--sticky{
	background:rgba(255, 255, 255, .8);
	.menu_line{
		background:#464389;
	}
}


.case_header{
	background:rgba(255, 255, 255, .8);
	.menu_line{
		background:#464389;
	}
}

.headerWrap{
	@include rem('padding',60px 68px 50px 60px);
}

.logo{
	float:left;
	display: inline-block;
	@include rem('width',140px);
	@include rem('height',45px);
}


.moto p{
	float: left;
	width: auto;
	@include rem('margin-left',63px);
	@include rem('font-size',14px);
	color:#464389;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 3em;
}

.menu{
	float: right;
	display: inline-block;
	@include rem('width',75px);
	@include rem('height',55px);
	position:relative;
	cursor: pointer;
	.menu_line{
		@include rem('width',75px);
		@include rem('height',4px);
		background:#fff;
		@include rem('margin-bottom',10px);
		transition:$transition1;
		&:last-child{
			@include rem('margin-bottom',0);
		}
	}
}

// About

.menu_purple{
	float: right;
	display: inline-block;
	@include rem('width',77px);
	@include rem('height',55px);
	cursor: pointer;
	position: relative;
	.menu_line{
		@include rem('width',75px);
		@include rem('height',4px);
		background:#464389;
		@include rem('margin-bottom',10px);
		transition:$transition1;
		transition-delay: 0s !important;
		&:last-child{
			@include rem('margin-bottom',0);
		}
	}
}


// menu
.menu_content{
	position:absolute;
	top:0;
	left:0;
	width: 100%;
	height: 100vh;
	background:transparent;
	z-index: -1;
	transition:$transition2;
 	visibility: hidden;
	opacity: 0;
	.column-4{
		height: 100%;
	}
}

.gray_background1{
	background:#222224;
}

.gray_background2{
	background:#171719;
}

.gray_background3{
	background:#000;
	position: relative;
}

.column_content{
    float: left;
	@include rem('margin-top',355px);
	@include rem('padding-left',70px);
	@include rem('padding-right',70px);
	a{
		float:left;
		text-decoration: none;
		color:white;
		@include rem('font-size',58px);
		position: relative;
		@include rem('margin-bottom',54px);
	    &.orange_border::before{
			content:'';
			position: absolute;
			@include rem('height',3px);
			@include rem('width',156px);
			background:#f07950;
			left:0;
			bottom:-30px;
		}

	    &.green_border::before{
			content:'';
			position: absolute;
			@include rem('height',3px);
			@include rem('width',156px);
			background:#33a692;
			left:0;
			bottom:-30px;
		}

	    &.blue_border::before{
			content:'';
			position: absolute;
			@include rem('height',3px);
			@include rem('width',156px);
			background:#464389;
			left:0;
			bottom:-30px;
		}
	}

	p{
		float:left;
		width: 100%;
		color:rgba(255,255,255, .3);
		@include rem('font-size',20px);
		line-height: 1.56;
	}
	ul{
		float:left;
		width: 100%;
		@include rem('margin-top',33px);
		&.orange_hover{
			a{
				&:hover{
					border-bottom:3px solid #f07950;
				}
			}
		}
		&.green_hover{
			a{
				&:hover{
					border-bottom:3px solid #33a692;
				}
			}
		}
		&.blue_hover{
			a{
				&:hover{
					border-bottom:3px solid #464389;
				}
			}
		}
	}
	li{
		float:left;
		width: 100%;
		@include rem('margin-bottom',32px);
		&:last-child{
			@include rem('margin-bottom',0);
		}
		a{
			text-decoration:none;
			color:#fff;
			@include rem('font-size',25px);
			font-weight:400;
			@include rem('margin-bottom',-13px);
			@include rem('padding-bottom',10px);
			border-bottom: 3px solid transparent;
			transition:$transition1;
		}
	}
}

.address{
	position: absolute;
	bottom:0;
	@include rem('margin-bottom',62px);
	@include rem('margin-left',70px);
	p{
		color:rgba(255,255,255, .3);
		@include rem('font-size',17px);
		line-height: 1.56em;
	}
}

.is--active{
	visibility:visible !important;
	opacity:1 !important;
}

.is--hidden{
	visibility:hidden !important;
	opacity:0 !important;
}

.lock_scroll {
    overflow: hidden;
}

.active{
	div.menu_line:nth-child(2),
	div.menu_line:nth-child(3){
		width: 0;
    	opacity:0;
	}
	div.menu_line:nth-child(1){
		position:absolute;
	    transform: rotate(-45deg);
	    top:25px;
	    background:#fff;
	}

	div.menu_line:nth-child(4){
		position:absolute;
	    transform: rotate(45deg);
	    top:26px;
	    background:#fff;

	}
}



