html{
	font-family: 'Montserrat';
}

.block_section{
	 float: left;
    width: 100%;
    clear: both;
}
body {
	@include flexCenter(true,false);

}
// homepage
.full_screen_page{
	height: 100vh;
	width: 100vw;
	position:fixed;
	a.homepage-column{
		float: left;
		height: 100%;
		text-decoration:none;
		&:hover{
			.more{
				p{
					@include rem('padding-right',100px);
				}
			}
		}
	}
}

.column_img{
	float:left;
	width: 100%;
	height: 100%;
	z-index:3;
	img{
		@extend %imgCropped;
	}
}

.vertical_block_1{
	position:relative; 
	.gradient_holder{
		position: absolute;
		top: 0;
		left:0;
		width: 100%;
		height: 100%;
		background:linear-gradient(0deg, rgba(59,168,149,0.9531162806919643) 27%, rgba(245,245,246,0) 37%);
		z-index:5;
	}
}

.vertical_block_2{
	position:relative;
	.gradient_holder{
		position: absolute;
		top: 0;
		left:0;
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, rgba(72,68,134,0.95) 27%, rgba(245,245,246,0) 37%);
		z-index:5;
	}
}

.vertical_block_3{
	position:relative;
	.gradient_holder{
		position: absolute;
		top: 0;
		left:0;
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, rgba(238,124,79,0.95) 27%, rgba(245,245,246,0) 37%);
		z-index:5;
	}
}

.category_title{
	width: 77%;
	position:absolute;
    right: 0;
    bottom: 0;
    z-index:7;
    @include rem('padding',55px 50px 55px 0);
	text-align: right;
	h1{
		@include rem('font-size',60px);
		color:#fff;
		font-weight: 500;
		text-align: right;
		transition:$transition2;
		@include rem('margin-bottom',52px);
		line-height: 1em;
	}
}

.more{
	float: right;
	p{
		color:#fff;
		@include rem('font-size',11.32px);
		text-transform: uppercase;
		letter-spacing: 3px;
		position: relative;
		transition:$transition2;
		@include rem('padding-right',67px);
		&:after{
			content:"";
			background:url('../../src/images/more_arrow.svg') no-repeat center;
			@include rem('width',57px);
			@include rem('height',24px);
			display: inline-block;
			position: absolute;
			right: 0;
			@include rem('bottom',-5px);
		    background-size: cover;
		}
	}
}


// About

.banner{
	float:left;
	width: 100%;
	height: 100vh;
	// @include rem('height',1080px);
	position: relative;
	img{
		@extend %imgCropped; 
	}
	&__title{
		position: absolute;
		@include rem('bottom',67px);
		left:50%;
		transform:translateX(-50%);
		h1{
			text-transform: uppercase;
			color:#fff;
			font-weight: 600;
			@include rem('font-size',90px);
		}
	}
}
.banner_small{
	height: 400px
}
.text_and_image_blocks{
	float:left;
	width: 100%;
	@include rem('height',653px);
	
}

.text_block{
	float:left;
	width: 50%;
	height: 100%;
	display: inline-block;
	@include rem('padding',93px 168px 107px 0);
	&__inner{
		float:right;
		@include rem('width',525px);
		h2{
			@include rem('font-size',40px);
			font-weight: 600;
			color:#000;
			@include rem('margin-bottom',70px);
		}
		p{
			@include rem('font-size',20px);
			font-weight: 400;
			line-height: 1.35em;
			color:#000;
		}
	}
}
.img_block{
	float:right;
	display: inline-block;
	width: 50%;
	height: 100%;
	img{
		@extend %imgCropped;
	}
}

.flipped_blocks{
	.text_block{
		float:right;
		@include rem('padding',93px 0px 107px 100px);
		&__inner{
			float:left;
		
		}

	}

	.img_block{
		float:left;
	}
}

.text_block_color1{
	background:#232323;
	.text_block__inner{
		h2,p{
			color:#fff;
		}
	}
}

.text_block_color2{
	background:#33a692;
	.text_block__inner{
		h2,p{
			color:#fff;
		}
	}
}

.partners{
	float:left;
	width: 100%;
	@include rem('padding',0 135px);
	@include rem('padding-top',118px);
	@include rem('padding-bottom',118px);
	h2{
		@include rem('font-size',40px);
		color:#232323;
		font-weight: 600;
		text-align: center;
		@include rem('margin-bottom',94px);
	}
	ul{
		float:left;
		width: 100%;
		li{
			float:left;
			display: inline-block;
			height: 200px;
			position: relative;
			img{
				position: absolute;
				top:50%;
				left:50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}


// Case

.case_banner__title{
	position: absolute;
	@include rem('bottom',383px);
	left:50%;
	transform:translateX(-50%);
    width: 100%;
    padding: 0px 220px;

	h1{
		color:#fff;
		font-weight: 600;
		@include rem('font-size',90px);
		text-align: center;
	}
}

.case_text_block{
	float: left;
	width: 62%;
	height: 100%;
	display: inline-block;
	@include rem('padding',57px 350px 43px 0);
	&__inner{
		float:right;
		@include rem('width',525px);
		h2{
			@include rem('font-size',40px);
			font-weight: 600;
			color:#000;
			@include rem('margin-bottom',40px);
		}
		p{
			@include rem('font-size',20px);
			font-weight: 400;
			line-height: 1.35em;
			color:#000;
			@include rem('margin-bottom',47px);
		}
	}
}

.case_img_block{
	float: right;
	width: 38%;
	display: inline-block;
	@include rem('height',653px);
	&_inner{
		width: 50vw;
	    height: 100%;
	}
	img{
		@extend %imgCropped;
	}
}

.case_text_and_image_blocks{
	float:left;
	width: 100%;
	overflow: hidden;
	@include rem('height',653px);
}

.share{
	float: left;
	p{
		@include rem('font-size',14px);
		text-transform: uppercase;
		color:#7a7a7a;
		font-weight: 600;
		@include rem('margin-bottom',10px);
	}

	ul{
		float:left;
		width: 100%;
		display: inline-block;
	}
	li{
		float:left;
		display: inline-block;
		@include rem('width',26px);
		@include rem('height',25px);
		@include rem('margin-right',18px);
		&:last-child{
			@include rem('margin-right',0);
		}
		a{
			text-decoration: none;
			img{
				@extend %imgCropped;
			}
		}
	}
}

.cases_navigation{
	float:left;
	width: 100%;
	background:#232323;
	@include rem('padding',60px 0);
	ul{
		float:left;
		width: 100%;
		display: flex;
		align-items:center;
		justify-content:space-between;
	}
	li{
		float:left;
		display: inline-block;
		width: auto;
		a{
			float:left;
			text-decoration:none;
			@include rem('font-size',15px);
			color:#6f6f6f;
			font-weight: 600;
			text-transform: uppercase;
			position: relative;

		}
	}
}

.prev_case a{
	@include rem('padding-left',113px);
	&::before{
		content:"";
		background:url('../../src/images/prev_arrow.svg') no-repeat center;
		position: absolute;
		@include rem('width',90px);
		@include rem('height',40px);
	    left: 0;
	    @include rem('bottom',-12px);
	    background-size:contain;
	}
}

.all_cases a{
	@include rem('padding-left',54px);
	&::before{
		content:'';
		background:url('../../src/images/all_cases_icon.svg') no-repeat center;
		@include rem('width',35px);
		@include rem('height',32px);
		position: absolute;
	    left: 0;
	    @include rem('bottom',-8px);
	    background-size:contain;
	}
}

.next_case a{
	@include rem('padding-right',113px);
	&::after{
		content:'';
		background:url('../../src/images/next_case_arrow.svg') no-repeat center;
		position: absolute;
		@include rem('width',90px);
		@include rem('height',40px);
		@include rem('right',0);
	    right: 0;
	    @include rem('bottom',-11px);
	    background-size:contain;
	}
}

.wpcf7 {
    width: 700px;
    padding: 20px 20px 20px 50px !important;
	margin: 50px auto!important;
	@include mobile {
		width: 100%;
		padding: 0!important
	}
	input,
	textarea {
		font-size: 16px;
		background-color: #f5f5f5;
		border: none;
		width: 100%;
		padding: 10px 20px;
		outline: 0;
		margin-top: 5px;
	}
	input[type="submit"] {
		float: right;
	    color: #fff;
	    font-size: 18px;
	    font-weight: 700;
	    background: #464388;
	    padding: 10px 29px;
	    border: none;
	    border-radius: 0px;
	    width: auto;
	    text-transform: uppercase;
	    float: right;
	    letter-spacing: 1px;
	    cursor: pointer;
	}
	input:hover[type="submit"] {
	    background: #494949;
	    transition: all 0.4s ease 0s;
	}
	input:active[type="submit"] {
	    background: #000000;
	}
	label{
		margin-bottom: 15px;
		float: left;
		width: 100%;
	}
}
div.wpcf7-response-output{
	float: left;
	width: 100%;
}
span.wpcf7-list-item {
	margin: 0;
	padding-bottom: 20px;
}
.header_height_space{
	@include rem('margin-top',200px);
	@include mobile {
	    @include rem('margin-top',20px);
	}
}
.article_post{
	@include rem('margin-top',60px);
	.article_header{
		text-align: center;
		@include rem('font-size',38px);
		font-weight: 600;
		color:#000;
		@include rem('margin-bottom',70px);
		@include mobile {
			@include rem('margin-bottom',30px);
		}
	}
	h2{
		@include rem('font-size',32px);
		font-weight: 600;
		color:#000;
		@include rem('margin-bottom',20px);
	}
	p{
		@include rem('font-size',20px);
		font-weight: 400;
		line-height: 1.35em;
		color:#000;
		@include rem('margin-bottom',20px);
	}
}
.article_main{
	@include rem('font-size', 18px);
  color: #150F3E;
  p, ul, ol, h2, h3, h4, h5, blockquote {
    @include rem('margin-bottom', 28px);
    line-height: 1.56em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  h2, h3, h4, h5 {
    @extend %fullBlock;
    font-weight: bold;
    @include rem('margin-top', 10px);
  }
  h2 {
    @include rem('font-size', 32px);
  }
  h3 {
    @include rem('font-size', 28px);
  }
  h4 {
    @include rem('font-size', 24px);
  }
  h5 {
    @include rem('font-size', 20px);
  }
  .instagram-media, .fb-video, .fb-post, .twitter-tweet, iframe {
    @include rem('margin-bottom', 28px);
    line-height: 1.56em !important;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    list-style-type: disc;
    @include rem('padding-left', 24px);
  }
  ol {
    list-style-type: decimal;
    @include rem('padding-left', 24px);
  }
  a {
    color: #464389;
    text-decoration: none;
  }
  blockquote {
    @include rem('margin-left', -74px);
    @include rem('padding-left', 74px);
    @include rem('padding-top', 5px);
    @include rem('padding-bottom', 5px);
    @include rem('font-size', 20px);
    float: left;
    border-left: 5px solid #464389;
    font-style: italic;
    quotes: "“" "”" "‘" "’";
    p {
      &:before {
        content: open-quote;
        float: left;
        font-weight: bold;
        @include rem('font-size', 42px);
        color: #464389;
        margin-right: 10px;
      }
    }
  }
}
.box{
	@extend %fullBlock;
	color: inherit;
	text-decoration: none;
	@include rem('margin-bottom',30px);
	&_thumb{
		@extend %fullBlock;
		@include rem('height',250px);
		@include rem('margin-bottom',20px);
		img{
			@extend %imgCropped;
		}
	}
	&_content{
		@extend %fullBlock;
		color: #150F3E;
		h1{
			@include rem('font-size',18px);
			line-height: 1.3em;
			font-weight: 600;
			@include rem('margin-bottom',7px);
		}
		p{
			@include rem('font-size',14px);
			line-height: 1.5em;
		}
	}
}
.home-top-pagination{
	@include rem('margin-top',50px);
  	@include rem('padding-top',40px);
  	@include rem('padding-bottom',54px);
  	border-top: 1px solid rgba(55, 67, 82, 0.1);
 	a{
	    float: left;
	    text-decoration: none;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    @include rem('width',47px);
	    @include rem('height',47px);
	    border-radius: 23.5px;
	    @include rem('font-size',12px);
	    font-weight: 900;
	    letter-spacing: 0.8px;
	    text-align: center;
	    color: #374352;
	    transition: $transition1;
	    &:hover {
    		color: #00bbe1;
	    }
	    &.active{
	    	box-shadow: 0 2px 12px 0 rgba(55, 67, 82, 0.15);
	    }
  	}
}
.clearleft{
	clear: left;
}