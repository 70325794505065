 @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    html{
    	font-size:80%;
    }

    .headerWrap{
    	@include rem('padding',30px 38px 20px 30px);
	}
	.category_title{
		h1{
			font-size:48px;
		}
	}
}

@media only screen  and (max-device-width: 960px) and (orientation: landscape) {
	#header{
		height: 60px !important;
		.headerWrap{
			padding: 14px 20px 13px 20px !important;
			.logo{
				width: 100px;
			}
			.moto p{
				font-size:12px;
			}
			.menu_content{
				overflow:scroll;
				.column-4{
					height: 280px;
				} 
			}
			#menu{
				width: 50px;
				.menu_line{
					width: 100%;
					margin-bottom: 6px;
					height: 3px;
				}
			}
			.menu_purple{
				width: 50px;
				.menu_line{
					width: 100%;
					margin-bottom: 6px;
					height: 3px;
				}
			}
		}
	}
	.mainWrap{
		.banner {
		    height: 75vh;
		    .case_banner__title {
		    	top:60%;
		    }
		}
		.block_section{
			.cases_navigation{
				@include rem('padding',30px 0);
			}
		}


		.full_screen_page {
		    position: static;
		    height: auto;
		    a.homepage-column{
	    	    float: left;
    			height: 280px;
    			.column_img img {
				    height: 100%;
				}
				.category_title{
					bottom:30px !important;
					h1{
						font-size: 40px !important;
						margin-bottom:25px;
					}
					.more{
						p{
							font-size:13px !important;
						}
					}
				}
		    }
		}
	}
}



@media only screen  and (max-device-width: 1024px) and (orientation: landscape) {
   .category_title{
   		bottom:18px !important;
   		h1{
   			font-size:45px !important;
   		}
   }

   .case_banner__title{
   		bottom:50px !important;
   		h1{
   			font-size:65px;
   		}
   }

   .main_header{
   		height: 85px !important;
   }
   .headerWrap{
   		padding:20px 20px 10px 20px !important;
   }

   .menu{
 		.menu_line{
 			background:#464389;
 		}
 	}

 	.banner__title{
 		bottom:15px;
 		h1{
 			font-size:65px;
 		}
 	}

 	.column_content{
 		margin-top:70px !important;
 		a{
 			font-size:30px !important;
 		}
 		p{
 			margin-top:30px !important;
 		}
 		ul{
 			margin-top:35px !important;
 			li{
 				margin-bottom: 25px;
 				a{
 					font-size:18px !important;
 				}
 			}
 		}
 	}
}



 @include mobile{
 	.main_header{
 		@include rem('height',64px);
 	}
 	.headerWrap{
 		@include rem('padding',15px);
 		height: 100%;
 	}
	.logo{
		@include rem('width',100px);
	}
 	.moto{
 		display: none;
 	}
 	.menu{
 		@include rem('width',45px);
 		.menu_line{
 			width: 100%;
 			@include rem('height',3px);
 			@include rem('margin-bottom',7px);
 			background:#464389;
 		}
 	}
 	.active div.menu_line:first-child{
 		@include rem('top',15px);
 	}
 	.active div.menu_line:nth-child(4){
 		@include rem('top',15px);
 	}
 	.menu_purple{
 		@include rem('width',45px);
 		.menu_line{
 			width: 100%;
 			@include rem('height',3px);
 			@include rem('margin-bottom',7px);
 		}
 	}
 	.full_screen_page{
 		position: static;
 		height: auto;
 		a.homepage-column{
 			float:left;
 			height:300px;
			.category_title {
 				opacity: 1!important;
			    transform: translateZ(0)!important;
			}
 		}
 	}
 	.category_title{
	    width: 100%;
 		@include rem('padding',20px);
 		h1 {
 			@include rem('font-size',35px);
		    font-size: 35px;
		    @include rem('margin-bottom',25px);
		}
 	} 
 	.vertical_block_first{
 		.gradient_holder {
		    background: linear-gradient(0deg,rgba(59,168,149,.953116) 27%,hsla(240,5%,96%,0) 100%);
 		}
 	} 

 	.vertical_block_second{
 		.gradient_holder{
 			background: linear-gradient(0deg,rgba(72,68,134,.95) 27%,hsla(240,5%,96%,0) 100%);
 		}
 	} 

 	.vertical_block_third{
 		.gradient_holder{
		    background: linear-gradient(0deg,rgba(238,124,79,.95) 27%,hsla(240,5%,96%,0) 100%);
 		}
 	} 

	.menu_content {
		.column-4 {
			height: 33.3333%;
		}
	}

	.column_content{
		@include rem('margin-top',50px);
		@include rem('padding',15px);
		a{
			@include rem('font-size',25px);
			@include rem('margin-bottom',0);
			&::before{
				width: 93px !important;
				bottom: -6px !important;
			}
		}
	
		p{
			@include rem('font-size',13px);
			@include rem('margin-top',20px);
			line-height: 1.2em;
		}

		ul{
			@include rem('margin-top',22px);
			li{
				@include rem('margin-bottom',15px);
				a{
					@include rem('font-size',15px);
					@include rem('padding-bottom',5px);
				}
			}
		}
	}
	.address{
		position: relative;
		float: left;
		width: 100%;
		margin:0;
		@include rem('padding',0px 15px 15px 15px);
		p{
			@include rem('font-size',13px);
			line-height: 1.2em;
		}
	}

	.banner{
		height: 45vh;
		&__title{
			@include rem('bottom',30px);
			h1{
				@include rem('font-size',33px);
			}
		}
	}

	.text_and_image_blocks{
		height: auto;
	}
	.text_block{
		float:left;
		width: 100%;
		@include rem('padding',30px 15px);
		&__inner{
			width: 100%;
			h2{
				@include rem('font-size',33px);
				@include rem('margin-bottom',15px);
			}
			p{
				@include rem('font-size',16px);
			}
		}
	}

	.flipped_blocks{
		.text_block{
			float:left;
			width: 100%;
			@include rem('padding',30px 15px);
			&__inner{
				width: 100%;
			}
		}
	}

	.img_block{
		float:left;
		width:100%;
		img{
			@include rem('margin-bottom',-2px);
		}
	}

	.partners{
		@include rem('padding',30px 0 50px 0);
		h2{
			@include rem('font-size',33px);
			@include rem('margin-bottom',20px);
		}

		ul{
			li{
				@include rem('height',110px);
			}
		}
	}

	.case_banner__title{
		width: auto;
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%,-50%);
		h1{
			@include rem('font-size',33px);
			text-align: center;
		}
	}

	.case_text_and_image_blocks{
		height: auto;
	}

	.case_text_block{
		width: 100%;
		padding:15px 0;
		.case_text_block__inner{
			width: 100%;
			h2{
				@include rem('font-size',33px);
				@include rem('margin-bottom',15px);
			}

			p{
				@include rem('font-size',16px);
				@include rem('margin-bottom',15px);
			}
		}
	}

	.case_img_block{
		width: 100%;
    	height: auto;
    	.case_img_block_inner{
    		width: 100%;
    		height: 250px;
    		img{
    			left: 50%;
			    margin-left: -50vw;
			    margin-right: -50vw;
			    max-width: 100vw;
			    position: relative;
			    right: 50%;
			    width: 100vw;
			    height: 100%;
    		}
    	}
	}

	.cases_navigation{
		@include rem('padding',30px 0);
		li{
			a{
				@include rem('font-size',10px);
			}
		}
	}

	.prev_case{
		a{
			@include rem('padding-left',30px);
			&::before{
				@include rem('width',23px);
				@include rem('bottom',-14px);
			}
		}
	}

	.next_case{
		a{
			@include rem('padding-right',30px);
			&::after{
				@include rem('width',23px);
				@include rem('bottom',-14px);
			}
		}
	}

	.all_cases {
		a{
			@include rem('padding-left',30px);
			&::before{
				@include rem('width',15px);
				@include rem('bottom',-10px);
			}
		}
	}

	.share{
		li{
			@include rem('width',22px);
			@include rem('height',21px);
			@include rem('margin-right',13px);
		}
	}
 }

 @include tablet{
 	.main_header{
 		@include rem('height',108px);
 	}

 	.headerWrap {
 		height: 100%;
 		@include rem('padding',30px 20px);
	}
 	.full_screen_page{
 		a.homepage-column{
 			float:left;
 			height: 33.333333%;
 			// height:calc(33% + 5px);
 			// @include rem('margin-bottom',-1px);
 		}
 	}

 	.menu{
 		.menu_line{
 			background:#464389;
 		}
 	}

 	.vertical_block_first{
 		.gradient_holder {
		    background: linear-gradient(0deg,rgba(59,168,149,.953116) 27%,hsla(240,5%,96%,0) 100%);
 		}
 	} 

 	.vertical_block_second{
 		.gradient_holder{
 			background: linear-gradient(0deg,rgba(72,68,134,.95) 27%,hsla(240,5%,96%,0) 100%);
 		}
 	} 

 	.vertical_block_third{
 		.gradient_holder{
		    background: linear-gradient(0deg,rgba(238,124,79,.95) 27%,hsla(240,5%,96%,0) 100%);
 		}
 	} 

 	.category_title{
 		width: 100%;
 		@include rem('padding',20px);
 		bottom:45px;
 		h1 {
 			@include rem('font-size',45px);
		    @include rem('margin-bottom',45px);
		}
		.more{
			p{
				@include rem('font-size',16px);
			}
		}
 	} 

 	.menu_content {
		.column-4 {
			height: 33.3333%;
		    // height: calc(33% + 5px);
		    @include rem('margin-bottom',-1px);
		}
	}

	.column_content{
		@include rem('margin-top',100px);
		@include rem('padding',20px);
		a{
			@include rem('font-size',40px);
			@include rem('margin-bottom',0);
			&::before{
				width: 115px !important;
				bottom: -10px !important;
			}
		}
	
		p{
			@include rem('font-size',16px);
			@include rem('margin-top',30px);
			line-height: 1.2em;
		}

		ul{
			@include rem('margin-top',50px);
			li{
				width: 50%;
				@include rem('margin-bottom',35px);
				a{
					@include rem('font-size',25px);
					@include rem('padding-bottom',5px);
				}
			}
		}
	}

	.address{
		right: 0;
		bottom:0;
		@include rem('margin-right',20px);
		@include rem('margin-bottom',30px);
		p{
			@include rem('font-size',16px);
		}
	}

	.banner{
		height: 50vh;
		.banner__title{
			@include rem('bottom',53px);
			h1{
				@include rem('font-size',60px);
			}
		}
	}
	.text_and_image_blocks{
		height: auto;
	}

	.text_block{
		float:left;
		width: 100%;
		@include rem('padding',50px 20px);
		&__inner{
			width: 100%;
			h2{
				@include rem('margin-bottom',20px);
			}
		}
	}

	.img_block{
		float:left;
		width: 100%;
		img{
			@include rem('margin-bottom',-2px);
		}
	}

	.flipped_blocks{
		.text_block{
			float:left;
			width: 100%;
			@include rem('padding',50px 20px);
			&__inner{
				width: 100%;
				h2{
					@include rem('margin-bottom',20px);
				}
			}
		}
	}

	.case_banner__title{
		@include rem('padding',0 60px);
		@include rem('bottom',100px);
		h1{
			@include rem('font-size',60px);
		}
	}

	.case_text_block{
	    width: 100%;
	    @include rem('padding',50px 0);
	    &__inner{
	    	width: 100%;
	    }
	}

	.case_text_and_image_blocks{
		height: auto;
	}

	.case_img_block{
		width: 100%;
		height: auto;
	}

	.case_img_block_inner{
		width: 100%;
		height: 450px;
		img{
		    left: 50%;
		    margin-left: -50vw;
		    margin-right: -50vw;
		    max-width: 100vw;
		    position: relative;
		    right: 50%;
		    width: 100vw;
		}
	}

	.column_img{
		img{
			height: 120%;
		}
	}

	.partners{
		@include rem('padding',50px);
		h2{
			@include rem('margin-bottom',30px);
		}
	}
 }

 @include tablet-big{
 	.main_header{
 		@include rem('height',116px);
 	}
 	.headerWrap {
    	padding: 35px 20px;
    }
	.banner{
		height: 50vh;
	}

	.case_banner__title {
		@include rem('bottom',172px);
	}

	.case_text_block{
		width: 100%;
	    padding: 30px 0;
	}
	.case_text_block__inner{
		width: 100%;
	}
	
	.case_text_and_image_blocks{
		height: auto;
	}

	.case_img_block{
		float:left;
		width:100%;
		.case_img_block_inner{
			left: 50%;
		    margin-left: -50vw;
		    margin-right: -50vw;
		    max-width: 100vw;
		    position: relative;
		    right: 50%;
		    width: 100vw;
		}
	}

	.full_screen_page{
 		a.homepage-column{
 			float:left;
 			height: 33.3333%;
 		}
 	}

 	.vertical_block_first{
 		.gradient_holder {
		    background: linear-gradient(0deg,rgba(59,168,149,.953116) 27%,hsla(240,5%,96%,0) 100%);
 		}
 	} 

 	.vertical_block_second{
 		.gradient_holder{
 			background: linear-gradient(0deg,rgba(72,68,134,.95) 27%,hsla(240,5%,96%,0) 100%);
 		}
 	} 

 	.vertical_block_third{
 		.gradient_holder{
		    background: linear-gradient(0deg,rgba(238,124,79,.95) 27%,hsla(240,5%,96%,0) 100%);
 		}
 	} 

 	.category_title{
 		width: 100%;
 		@include rem('padding',20px);
 		bottom:45px;
 		h1 {
 			@include rem('font-size',60px);
		    @include rem('margin-bottom',45px);
		}
		.more{
			p{
				@include rem('font-size',16px);
			}
		}
 	} 

 	.menu_content {
		.column-4 {
			height: 33.3333% !important;
		    @include rem('margin-bottom',-1px);
		}
	}

	.column_content{
		@include rem('margin-top',100px);
		@include rem('padding',20px);
		a{
			@include rem('font-size',40px);
			@include rem('margin-bottom',0);
			&::before{
				width: 115px !important;
				bottom: -10px !important;
			}
		}
	
		p{
			@include rem('font-size',18px);
			@include rem('margin-top',53px);
			line-height: 1.2em;
		}

		ul{
			@include rem('margin-top',50px);
			li{
				width: 50%;
				@include rem('margin-bottom',35px);
				a{
					@include rem('font-size',25px);
					@include rem('padding-bottom',5px);
				}
			}
		}
	}

	.address{
		right: 0;
		bottom:0;
		@include rem('margin-right',20px);
		@include rem('margin-bottom',70px);
		p{
			@include rem('font-size',16px);
		}
	}

	.text_and_image_blocks{
		height: auto;
	}

	.text_block{
	    width: 100%;
	    padding: 50px 20px;
	    .text_block__inner{
	    	width: 100%;
	    	h2{
	    		@include rem('margin-bottom',30px);
	    	}
	    }
	}
	.img_block{
		float:left;
		width: 100%;
		height: 600px;
		img{
			@include rem('margin-bottom',-2px);
		}
	}

	.flipped_blocks{
		height: auto;
		.text_block{
			 float: left;
		    width: 100%;
		    @include rem('padding',50px 20px);
		}

		.img_block{
			float:left;
			width: 100%;
			height: 600px;
			img{
				@include rem('margin-bottom',-2px);
			}
		}
	}

	.column_img{
		img{
			height: 120%;
		}
	}

	.partners{
		@include rem('padding',60px);
		h2{
			@include rem('margin-bottom',30px);
		}
	}
 }